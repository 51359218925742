import * as React from "react";

import { cn } from "@/shared/utils";

export interface InputProps extends React.InputHTMLAttributes<HTMLInputElement> {}

const defaultClasses =
    "px-4 h-11 w-full bg-background-primary rounded-md text-content-primary text-base appearance-none focus:outline-none peer placeholder:opacity-0 focus:placeholder:opacity-100 placeholder:text-content-secondary " as const;

const Input = React.forwardRef<HTMLInputElement, InputProps>(({ className, type, placeholder = "", ...props }, ref) => {
    return <input type={type} className={cn(defaultClasses, className)} placeholder={placeholder} ref={ref} {...props} />;
});

Input.displayName = "Input";

export { Input };
