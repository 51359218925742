"use client";

import { BuilderContent, Content, fetchOneEntry, isEditing } from "@builder.io/sdk-react/edge";
import { PublicConfig } from "@/shared/PublicConfig";
import { useSearchParams } from "next/navigation";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { PersonalizationAttributes } from "@/shared/types/PersonalizationAttributes";
import { Dialog, DialogClose, DialogContent, DialogHeader } from "@/shared/components/ui";
import Link from "next/link";
import isEqual from "react-fast-compare";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import dynamic from "next/dynamic";
import { JSONFormProps } from "@/shared/components/JSONForm/JSONForm";
import { FieldValues } from "react-hook-form";
import { Quote } from "../types/Quote.interface";
import { BuilderUtils, BuilderDataType } from "../utils/BuilderUtils";

// Dynamic import for JSONForm component
const JSONForm = dynamic(() => import("@/shared/components/JSONForm/JSONForm"));

const JSONFormCustomComponent = {
    name: "JSON Form",
    inputs: [
        { name: "formID", type: "text", friendlyName: "Form ID", required: true },
        { name: "dataSchema", type: "text", friendlyName: "Data Schema" },
        { name: "uiSchema", type: "text", friendlyName: "UI Schema" },
        { name: "submitButtonTitle", type: "text", friendlyName: "Submit Button Title" }
    ]
};

export interface CustomizationSlotProps {
    type:
        | "co-brand"
        | "form-header"
        | "form-footer"
        | "modal"
        | "symbol"
        | "announcement-bar"
        | "above-cta"
        | "above-disclaimer"
        | "terms-disclaimer"
        | "thank-you"
        | "thank-you-sidebar"
        | "above-preventive";
    modalData?: FieldValues;
    formData?: FieldValues;
    updateData?: (dataArray: { type: BuilderDataType; data: FieldValues }[]) => Promise<void>;
    data?: Quote;
    formId?: string;
    formStepId?: string;
}

export function CustomizationSlot(props: CustomizationSlotProps) {
    const { type, modalData, formData, updateData, data, formId, formStepId } = props;

    const searchParams = useSearchParams();
    const [content, setContent] = useState<BuilderContent | null>(null);
    const lastAttributes = useRef<PersonalizationAttributes>();
    const editing = isEditing(searchParams);
    const isEditingType = editing && searchParams.get("builder.preview") === props.type;
    const [modalOpen, setModalOpen] = useState(false);

    const shouldDisplayModal = useCallback(
        (modalGroupID: string): boolean => {
            const modalStates = modalData ?? {};
            return !modalStates[modalGroupID];
        },
        [modalData]
    );

    useEffect(() => {
        const attributes = BuilderUtils.getBuilderAttributes({ data, formId, formStepId });
        attributes.queryParamsStringLowerCase = searchParams.toString().toLowerCase();

        // Only contact Builder.io if the attributes of this form changed
        if (isEqual(attributes, lastAttributes.current)) {
            return;
        }

        lastAttributes.current = attributes;

        fetchOneEntry({
            apiKey: PublicConfig.BUILDER_IO_PUBLIC_API_KEY,
            model: type,
            userAttributes: attributes
        })
            .then(result => {
                setContent(result);

                if (type === "modal" && result) {
                    // Should we only display this once, and has it already been displayed before for this quote?
                    if (!result?.data?.displayOnce || shouldDisplayModal(result.data.displayOnceGroupId ?? result.id!)) {
                        setModalOpen(true);
                    }
                }
            })
            .catch(error => {
                console.warn("Error fetching Builder.io content", error);
            });
    }, [formId, formStepId, data, searchParams, shouldDisplayModal, type]);

    // If we're not editing and there is no content, then return nothing
    if (!editing && !content) {
        return null;
    }

    // If we are editing, but we're not editing this current type, then return nothing
    if (editing && !isEditingType) {
        return null;
    }

    async function handleModalClose() {
        if (editing) return;

        setModalOpen(false);
        const formGroupID = content?.data?.displayOnceGroupId ?? content?.id;
        if (content?.data?.displayOnce && formGroupID && updateData) {
            // If this modal is set to only display once, then make sure we save the state as part of the quote extra data.
            await updateData([{ type: "modal", data: { ...modalData, [formGroupID]: true } }]);
        }
    }

    async function handleFormSubmit(jsonFormProps: JSONFormProps, newFormData: any) {
        if (updateData) {
            const formUpdateData: { type: BuilderDataType; data: FieldValues } = { type: "form", data: { ...formData, [jsonFormProps.formID]: newFormData } };
            const updates = [formUpdateData];

            if (props.type === "modal") {
                const formGroupID = content?.data?.displayOnceGroupId ?? content?.id;
                const modalUpdateData: { type: BuilderDataType; data: FieldValues } = { type: "modal", data: { ...modalData, [formGroupID]: true } };
                updates.push(modalUpdateData);
            }

            await updateData(updates);
            if (props.type === "modal") {
                setModalOpen(false);
            }
        }
    }

    function renderContent() {
        return (
            <>
                {!!content && (
                    <div>
                        <Content
                            apiKey={PublicConfig.BUILDER_IO_PUBLIC_API_KEY}
                            content={content}
                            model={props.type}
                            linkComponent={Link}
                            data={lastAttributes.current}
                            customComponents={[
                                {
                                    ...JSONFormCustomComponent,
                                    component: (props: JSONFormProps) => {
                                        return <JSONForm {...props} initialData={formData?.[props.formID]} onSubmit={data => handleFormSubmit(props, data)} />;
                                    }
                                }
                            ]}
                        />
                    </div>
                )}
            </>
        );
    }

    if (props.type === "modal") {
        return (
            // The dialog component in modal mode doesn't work well in Builder.io's editor, so we need turn modal=false when in edit mode
            <Dialog open={editing || modalOpen} modal={!editing}>
                <DialogContent className={`max-w-screen-md ${content?.data?.noPadding ? "md:p-0" : ""} ${editing ? "border-8" : ""}`}>
                    {content?.data?.allowManualClose && (
                        <DialogHeader className="m-0 contents p-0">
                            <DialogClose onClick={handleModalClose} className="z-30 text-content-primary-invert">
                                <FontAwesomeIcon icon={faTimes} className="inline-block size-6" size="lg" />
                            </DialogClose>
                        </DialogHeader>
                    )}
                    {!!content && renderContent()}
                </DialogContent>
            </Dialog>
        );
    }

    return renderContent();
}
