import * as React from "react";
import { Input, InputProps } from "./Input";
import { Label } from "./label";
import { RefCallback, RefObject } from "react";
import { cn } from "@/shared/utils";
import { ErrorMessage } from "../FormField";

interface InputWithLabelProps {
    /**
     * Used to connect the `<label>` to the `<input>`
     */
    id?: string;
    /**
     * Input label text.
     */
    label: string;
    /**
     * Pass `HTMLInputElement` properties to the `<input>`
     */
    inputProps?: InputProps;
    inputRef?: RefObject<HTMLInputElement> | RefCallback<HTMLInputElement>;
    /**
     * Pass Icon/SVG to render before the `<input>`.
     */
    startDecorator?: React.ReactNode;
    /**
     * Input wrapper class.
     */
    inputWrapperClass?: string;

    error?: ErrorMessage;
}

/**
 * Text Input with floating label on focus.
 */

const InputWithLabel = (props: InputWithLabelProps) => {
    const { id, label, inputWrapperClass, inputProps, inputRef, startDecorator, error } = props;
    const inputId = !id ? label.split(" ").join("-").toLowerCase() : id;
    const inputHasValue = String(inputProps?.value ?? "").length > 0;
    const inputClass = `input-value-${inputHasValue}`;
    // Default placeholder to a space to ensure peer-placeholder-shown pseudo-class works as expected (specifically for Safari)
    const placeholder = inputProps?.placeholder ?? " ";

    const inputWrapperClassConditions = {
        "shadow-faux-border-danger": !!error
    };

    const labelClassConditions = {
        "left-10": startDecorator && !inputHasValue
    };

    return (
        <div className={cn("relative flex items-center rounded-md border border-none bg-background-primary shadow-faux-border", inputWrapperClassConditions, inputWrapperClass)}>
            {startDecorator && <span className="flex pl-3">{startDecorator}</span>}
            <Input id={inputId} className={inputClass} ref={inputRef} placeholder={placeholder} {...inputProps} />
            <Label htmlFor={inputId} variant="floating" className={cn("", labelClassConditions)}>
                {label}
            </Label>
        </div>
    );
};
InputWithLabel.displayName = "InputWithLabel";

export { InputWithLabel };
